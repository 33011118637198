<!-- #################################################################################### -->
<!-- ###### HERINCO                                                                ###### -->
<!-- ###### @author: John David Vásquez Serna                                      ###### -->
<!-- ###### @date: Enero 2025                                                      ###### -->
<!-- #################################################################################### -->

<!-- #################################################################################### -->
<!-- ###### Sección de HTML                                                        ###### -->
<!-- #################################################################################### -->
<template>
  <section class="subHeader-content">
    <blueHeader :itemsHeader="itemsHeader" />

    <!-- Seccion para cargar el contenido de la navegación de las rutas hijas del submenu-->
    <v-slide-y-transition mode="out-in">
      <router-view></router-view>
    </v-slide-y-transition>

  </section>
</template>
 
<script>
import { Role } from "@/router/role.js";
import BlueHeader from '../../../../components/BlueHeader.vue';

export default {
  components: { BlueHeader },
  name: 'Billing',
  data: () => ({
    itemsHeader: [
      {
        name: 'Radicación Nueva EPS',
        link: { name: 'modules.reports.billing.radicacionFactura' },
        rol: Role.Reportes_Facturacion_Radicacion,
      },

    ]
  }),
}
</script>
 
<style scoped>
.subHeader-content {
  width: 100%;
}
</style>